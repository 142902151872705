import React, { Fragment, useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet-async'
import queryString from 'query-string'
import { Container } from 'semantic-ui-react'
import JobDescriptionFullApply from 'JobDescriptionFullApply'
import JobDescriptionQuickApply from 'JobDescriptionQuickApply'
import axios from 'axios'
import * as Styled from 'jobDescriptionQuickApply_styled'
import { useTranslation } from 'react-i18next'
import { saveRouteData } from '../../../actions/global_actions'
import {
  getNumberOfQuestionsAction,
  getCompanyProfileByIdAction,
  getJobDescriptionAction,
  getUploadedResumeFileAction,
} from '../../../actions/api_actions'
import { saveAuthenticationData } from '../../../actions/authentication_actions'
import { setJobDescription } from '../../../actions/jobDescription_actions'
import { apiUrl } from '../../../../constants/urlConstants'
import { isEmpty } from '../../../../util'

const JobDescription = (props) => {
  const {
    jobDescription,
    setJobDescription,
    match,
    location,
    interviewGuide,
    numberOfQuestions,
    applicant,
    route,
    saveRouteData,
    getNumberOfQuestionsAction,
    getCompanyProfileByIdAction,
    getJobDescriptionAction,
    saveAuthenticationData,
    getUploadedResumeFileAction,
  } = props

  const {
    i18n,
    i18n: { language },
  } = useTranslation()

  const updateTraductions = () => {
    console.info('UPDATE TRADUCTIONS')
    setJobDescription({
      language: 'es',
    })
    apiUrl(window.activeEnvironment, 'integrations', 'questionTranslate').then((url) => axios
      .get(`${url}${jobDescription?.application_form_template_id}`, { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${(localStorage ? (localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')).value : '') : '')}` } })
      .then(({ data }) => {
        const questionsEs = data?.data?.questions_es
        if (questionsEs) {
          setJobDescription({
            questions_translation: !isEmpty(questionsEs) ? ({ questions_es: questionsEs }) : null,
          })
        }
        apiUrl(window.activeEnvironment, 'integrations', 'jobTranslate').then((jobUrl) => axios
          .get(`${jobUrl}${jobDescription.id}`, { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${(localStorage ? (localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')).value : '') : '')}` } })
          .then(({ data: { data } }) => {
            setJobDescription({
              job_translation: data.length === 1 ? data[0].fields_values : null,
            })
          })
          .catch((error) => {
            // handle error
            if (error.response?.status === 404 || error.response?.status === 403) {
              console.log(
                'ERROR JOB TRANSLATION',
                error.response.data.developer_message
              )
            }
            return error
          }))
      }))
  }

  const decodeJobIdParam = () => {
    let jobId = String(match.params.slug)
    if (isNaN(jobId)) {
      const hexJobId = `${jobId.slice(0, 2)}${jobId.slice(3)}`
      // eslint-disable-next-line radix
      const allDecodeNumber = String(BigInt(`0x${hexJobId}`))
      const lastCharacter = BigInt(allDecodeNumber[allDecodeNumber.length - 1])
      const decodeNumber = BigInt(allDecodeNumber.slice(0, -1))
      // eslint-disable-next-line no-bitwise
      jobId = Number(decodeNumber >> lastCharacter)
    }
    return jobId
  }

  const getJobIdParamToRequest = () => {
    const jobIdToEvaluate = decodeJobIdParam()
    const jobIdToRequest = parseInt(jobIdToEvaluate, 10) % 100000000
    return parseInt(jobIdToRequest, 10)
  }

  const applyChurchillRedirect = () => {
    /* Since full Apply is not ready jet we redirect to Churchill */
    apiUrl(
      window.activeEnvironment,
      'churchillRedirect',
      'churchillJobRedirect'
    ).then((url) => {
      /* Split url based on dot */
      const splitUrl = url.split('.')
      /* Add "-screening" to the first element */
      const attachUrl = `${splitUrl[0]}-screening`
      let combineUrl = `${attachUrl}`

      /* Loop through array and build the string */
      for (let i = 0; i < splitUrl.length; i += 1) {
        if (i > 0) {
          combineUrl += `.${splitUrl[i]}`
        }
      }

      const jobIdToRequest = getJobIdParamToRequest()
      window.location.href = `${combineUrl}${jobIdToRequest}${location.search}`
    })
  }

  const applyChurchillRedirectFirstStage = () => {
    /* Redirect to Churchill everything except when interviewGuide is active or second stage job */
    if (
      interviewGuide ||
      jobDescription.job_stage_num >= 2
    ) {
      return (
        <JobDescriptionFullApply
          matchData={match}
          interviewGuide={interviewGuide}
        />
      )
    }
    applyChurchillRedirect()
  }

  const renderOrRedirect = () => {
    /* if 'post_capture_url_via_email' exists and it doesn't contain 'apploi' string redirect
    to the link in that property.
    If property doesn't exists or is null go to QuickApply or FullApply route based on the number
    of questions or if user is logged in or not */
    if (
      jobDescription.post_capture_url_via_email &&
      jobDescription.post_capture_url_via_email.indexOf('apploi') === -1
    ) {
      window.location.href = jobDescription.post_capture_url_via_email
      return true
    }
    if (numberOfQuestions.num_questions_in_job > 0 || applicant) {
      /* ------------------ */
      // applyChurchillRedirect()
      // return
      /* ------------------ */
      // return applyChurchillRedirectFirstStage()
      /* ------------------ */
      return (
        <JobDescriptionFullApply
          matchData={match}
          interviewGuide={interviewGuide}
        />
      )
    }
    return (
      <Container>
        <JobDescriptionQuickApply matchData={props.match} />
      </Container>
    )
  }

  useEffect(() => {
    const jobIdToRequest = getJobIdParamToRequest()

    /* Show top of the page always */
    window.scrollTo(0, 0)

    /* save global route data */
    saveRouteData(route, match, location)

    /* API fetch */
    getCompanyProfileByIdAction(
      window.activeEnvironment,
      jobIdToRequest
    )
    getJobDescriptionAction(
      window.activeEnvironment,
      jobIdToRequest,
      window.location.protocol,
      window.location.hostname,
      interviewGuide
    ).then((rsp) => {
      const isActive = rsp?.payload?.jobDescription?.team?.public_features_options?.public_translation === true
      const isNotPresent = typeof (rsp?.payload?.jobDescription?.team?.public_features_options?.public_translation) === 'undefined'
      if (
        (isActive || isNotPresent) &&
        queryString.parse(location.search)?.language === 'es'
      ) {
        console.info('CHANGE TO ES')
        i18n.changeLanguage('es')
      }
    })

    /* If user applied to some job before (quick apply), then the `person_id` will be generated
    based on the email used and saved to local storage.
    If there is person_id in local storage we call API to get the list of jobs that user applied to.
    In local storage is saved the `person_id` of the last user that applied with specific email.
    If user logged in / registered he also has 'person_id' but the property is called just 'id'
    and it's inside 'applicant' in localStorage.
    */
    let authenticationTokenValue = 'undefined'
    if (typeof window !== 'undefined' && window.localStorage) {
      /* We get 'applicant' and 'token' in localStorage after user login/register/reset password */

      const authenticationApplicant = JSON.parse(
        localStorage.getItem('applicant')
      )
      const authenticationToken = JSON.parse(localStorage.getItem('token'))

      /* We get 'easyApplyApplicant' in localStorage after user apply for quick apply job */
      const authenticationEasyApplicant = JSON.parse(
        localStorage.getItem('easyApplyApplicant')
      )

      /* If there is 'applicant' and 'token' property in localStorage, we also save it to
      redux store. We do this because when we reload the page the redux store goes empty.
      This same data is also saved to redux
      when we login/register/change password inside the app, but we must do it again here because
      it gets removed from redux store if we reload */

      if (authenticationApplicant && authenticationToken) {
        authenticationTokenValue = authenticationToken.value
        const authenticationData = {
          ...authenticationApplicant,
          token: authenticationToken.value,
        }
        saveAuthenticationData(authenticationData, 'applicant')

        /* If we have applicants id, job id and token we can fetch resume that he uploaded or a
        template if not */
        /* We fetch resume file only if interview guide is active */
        if (interviewGuide) {
          getUploadedResumeFileAction(
            window.activeEnvironment,
            authenticationApplicant.value.id,
            jobIdToRequest,
            authenticationToken.value
          )
        }
      }
      
      getNumberOfQuestionsAction(
        window.activeEnvironment,
        jobIdToRequest,
        authenticationTokenValue
      )

      /* If there is 'easyApplyApplicant' property in localStorage, we also save it to redux store.
      We do this because when we reload the page the redux store goes empty. This same data is also
      saved to redux.
      when we submit quick apply form (we can only submit this when not logged in and when number of
      questions is 0) inside the app, but we must do it again here because it gets removed from
      redux store if we reload  */

      if (authenticationEasyApplicant) {
        const authenticationData = {
          ...authenticationEasyApplicant,
        }
        saveAuthenticationData(
          authenticationData,
          'easyApplyApplicant'
        )
      }
    }
  }, [])

  const isActive = jobDescription?.team?.public_features_options?.public_translation === true
  const isNotPresent = typeof (jobDescription?.team?.public_features_options?.public_translation) === 'undefined'
  useEffect(() => {
    if (
      (isActive || isNotPresent) &&
      jobDescription?.language !== language
    ) {
      if (
        language === 'es'
      ) {
        updateTraductions()
      } else {
        setJobDescription({
          language,
        })
      }
    } else if (!(isActive || isNotPresent) && i18n && language === 'es') {
      console.info('CHANGE TO EN')
      i18n.changeLanguage('en')
    }
  }, [jobDescription?.language, i18n, language,])

  return (
    <Fragment>
      <Helmet>
        <title>
          {jobDescription && jobDescription.name ? jobDescription.name : ''}
        </title>
        <meta
          property="og:title"
          content={
              jobDescription && jobDescription.name ? jobDescription.name : ''
            }
        />
        <meta
          property="og:description"
          content={
              jobDescription && jobDescription.description ?
                jobDescription.description
                  .replace(/<[^>]+>/g, '')
                  .replace(/&nbsp;/g, ' ')
                  .replace(/\&amp;/g, '&') :
                ''
            }
        />
        <meta
          property="og:image"
          content={
              jobDescription &&
              jobDescription.company_logo_url &&
              jobDescription.show_company_logo ?
                jobDescription.company_logo_url :
                ''
            }
        />
        <meta property="og:type" content="website" />
        <meta
          name="twitter:title"
          content={
              jobDescription && jobDescription.name ? jobDescription.name : ''
            }
        />
        <meta
          name="twitter:description"
          content={
              jobDescription && jobDescription.description ?
                jobDescription.description
                  .replace(/<[^>]+>/g, '')
                  .replace(/&nbsp;/g, ' ')
                  .replace(/\&amp;/g, '&') :
                ''
            }
        />
        <meta
          name="twitter:image"
          content={
              jobDescription &&
              jobDescription.company_logo_url &&
              jobDescription.show_company_logo ?
                jobDescription.company_logo_url :
                ''
            }
        />
        <meta name="twitter:card" content="summary_large_image" />

        {/* Google for jobs script */}
        <script type="application/ld+json">
          {JSON.stringify({
            ...(jobDescription && jobDescription.job_posting_spec ?
              jobDescription.job_posting_spec :
              ''),
          })}
        </script>
      </Helmet>

      {/* We use match to get the slug to see if user has already applied to the specific job */}
      <Styled.TranslatorWithContent data-testid="job-description">
        {numberOfQuestions &&
          jobDescription &&
          typeof window !== 'undefined' ?
          renderOrRedirect() :
          null}
      </Styled.TranslatorWithContent>
    </Fragment>
  )
}

JobDescription.serverFetch = [
  getNumberOfQuestionsAction,
  getCompanyProfileByIdAction,
  getJobDescriptionAction,
]

const mapStateToProps = (state) => ({
  jobDescription: state.jobDescriptionReducer.jobDescription,
  numberOfQuestions: state.jobDescriptionReducer.numberOfQuestions,
  applicant: state.authenticationReducer.applicant,
})

JobDescription.propTypes = {
  jobDescription: PropTypes.object, // comes from jobDescriptionReducer. Contains data about current active job.
  numberOfQuestions: PropTypes.object, // comes from jobDescriptionReducer. Contains data about number of questions the current active job has.
  applicant: PropTypes.object, // comes from authenticationReducer. Contains data about the current logged in user.
  interviewGuide: PropTypes.bool, // tells us if we should show resume.
  route: PropTypes.object, // comes from react-router-config. We are free to add any additional props we would like to a route inside of 'router.js'.
  location: PropTypes.object, // route prop (from react-router). Represent where the app is now.
  match: PropTypes.object, // route prop (from react-router). Contains information about how a <Route path> matched the URL. match objects.
  saveRouteData: PropTypes.func, // save to redux global route data that we get from react-router and react-router-config to use it across the app.
  getNumberOfQuestionsAction: PropTypes.func, // fetch number of questions in a current active job,
  getCompanyProfileByIdAction: PropTypes.func, // fetch company profile data using current active job id.
  getJobDescriptionAction: PropTypes.func, // fetch current active job data.
  getUploadedResumeFileAction: PropTypes.func, // fetch uploaded resume external link
  saveAuthenticationData: PropTypes.func, // use it to save 'applicant' and 'easyApplyApplicant' from local storage to redux store.
}

export default connect(mapStateToProps, {
  saveRouteData,
  getNumberOfQuestionsAction,
  getCompanyProfileByIdAction,
  getJobDescriptionAction,
  getUploadedResumeFileAction,
  saveAuthenticationData,
  setJobDescription,
})(JobDescription)
