import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import * as Styled from 'jobDescriptionFullApplyForm_styled'
import { Checkbox, Form, Icon } from 'semantic-ui-react'
import Scroll, { Link, animateScroll as scroll } from 'react-scroll'
import { withTranslation } from 'react-i18next'
import JobDescriptionFullApplyFormQuestions from 'JobDescriptionFullApplyFormQuestions'
import {
  openSubmitModal,
  openTimeoutImageModal,
} from '../../../../../../actions/modals_actions'
import { desktopDesign } from '../../../../../../helperFunctions/helperFunctions'
import {
  changeQuestionExpand,
  saveSubmitButtonClicked,
  disableFullApplyFormAutosave,
  startFullApplyFormActivate,
} from '../../../../../../actions/jobApplication_actions'
import {
  postFullApplyFormQuestionsAutosaveAction,
  postFullApplyFormPersonalQuestionsAutosaveAction,
} from '../../../../../../actions/api_actions'
import { validPhoneNumberToBeInE164FormatRudderstack } from '../../../../../../rudderstack/rudderstack'
import {
  isValidPhoneNumber
} from 'libphonenumber-js'
import { sanitizeString } from '../../../../../../../util'

const FullApplyFormScroll = Scroll.Element

class JobDescriptionFullApplyForm extends Component {
  state = {
    expandStatus: 'allQuestionsExpand',
    hasAgreedToTerms: false,
  }

  autoSaveInterval = null

  componentDidMount() {
    this.checkAllQuestionsExpandStatus()
    if (this.props.interviewGuide && typeof window !== 'undefined') {
      window.addEventListener('beforeunload', this.userLogout)
    }
  }

  componentDidUpdate(prevProps) {
    /* We change Expand All/Collapse all, also when we expand and collapse individual questions.
    Because of that we need to check it in ComponentDidUpdate as well as in ComponentDidMount for first render.
    We need to stringify objects in order to compare them so we trigger the check only when prop is updated */
    if (
      JSON.stringify(prevProps.questionExpand) !==
      JSON.stringify(this.props.questionExpand)
    ) {
      this.checkAllQuestionsExpandStatus()
    }

    /* We start autosave after fullApplyFormActivate becomes true  */

    if (
      prevProps.fullApplyFormActivate === false &&
      this.props.fullApplyFormActivate === true
    ) {
      this.autoSaveInterval = setInterval(
        () => this.handleFormAutoSave(),
        10000
      )
    }
  }

  componentWillUnmount() {
    if (this.props.interviewGuide) {
      window.removeEventListener('beforeunload', this.userLogout)
    }
    /* When exiting the page we return form activate to false and we clear the interval for autosave */
    this.props.startFullApplyFormActivate(false)
    clearInterval(this.autoSaveInterval)
    /* When exiting we also reset firstTimeSubmitButtonClicked to 'false' to not see the flash of
    design if form was not submitted and questions are red. */
    this.props.saveSubmitButtonClicked(false)
  }

  userLogout(e) {
    /* If interviewGuide is active we logout users when they leave Bolivar or Submit.
    We do it like this because users can just open interviewGuide and without submitting nothing
    close the page. At that moment if they visit normal Bolivar they would still be logged in. */

    localStorage.removeItem('applicant')
    localStorage.removeItem('token')
  }

  scrollToTop() {
    scroll.scrollToTop()
  }

  handleFormAutoSave = () => {
    const { t } = this.props
    /* Since we are using different APIs for Truman questions and Personal Questions we check here for both
    if we should trigger autosave */

    /* Truman questions */
    if (
      this.props.fullApplyFormQuestionsAutosave === true &&
      typeof window !== 'undefined' &&
      this.props.answers &&
      this.props.fullApplicationForm &&
      this.props.applicant
    ) {
      this.props
        .postFullApplyFormQuestionsAutosaveAction(
          window.activeEnvironment,
          this.props.answers,
          this.props.fullApplicationForm.id,
          this.props.applicant.token,
          this.props.interviewGuide
        )
        .then(() => {
          /* we send which autosave properties we wanna disable. We do this because it could happen that
          when the interval is done and fullApplyFormQuestionsAutosave and fullApplyFormPersonalQuestionsAutosave are true it needs to call two APIs. In case we get back response and disable both fullApplyFormQuestionsAutosave and fullApplyFormPersonalQuestionsAutosave before autosave for  fullApplyFormPersonalQuestionsAutosave is triggered we could skip autosave for that part */
          this.props.disableFullApplyFormAutosave([
            'fullApplyFormQuestionsAutosave',
          ])
        })
        .catch((error) => {
          this.props.disableFullApplyFormAutosave([
            'fullApplyFormQuestionsAutosave',
          ])
          this.props.openTimeoutImageModal({
            image: 'error',
            bodyMessage: t('autoSaveFailed') || error.developer_message,
            timeout: 2000,
            pageReload: false,
            redirect: false,
            removeCloseModalOptions: false,
          })
        })
    }

    /* Personal Questions - Person, Education, Workhistory */
    if (
      this.props.fullApplyFormPersonalQuestionsAutosave === true &&
      typeof window !== 'undefined' &&
      this.props.fullApplicationForm &&
      this.props.applicant
    ) {
      this.props
        .postFullApplyFormPersonalQuestionsAutosaveAction({
          environment: window.activeEnvironment,
          personalAnswers: this.props.fullApplicationPersonalQuestions,
          id: this.props.fullApplicationForm.applicant_id,
          token: this.props.applicant.token,
        })
        .then((response) => {
          console.log('Autosave-', response)
          this.props.disableFullApplyFormAutosave([
            'fullApplyFormPersonalQuestionsAutosave',
          ])
        })
        .catch((error) => {
          this.props.disableFullApplyFormAutosave([
            'fullApplyFormPersonalQuestionsAutosave',
          ])
          this.props.openTimeoutImageModal({
            image: 'error',
            bodyMessage:
              t('personalQuestionsAutoSaveFailed') || error.developer_message,
            timeout: 2000,
            pageReload: false,
            redirect: false,
            removeCloseModalOptions: false,
          })
        })
    }
  }

  handleFormSubmit = () => {
    const { t } = this.props
    /* Save in redux that Submit button has been clicked for the first time after opening the page */
    this.props.saveSubmitButtonClicked(true)

    /* turn object to array of objects and check if the questions are submit ready - truman questions */
    const answersRequirementsArray = Object.keys(
      this.props.answersRequirements
    ).map((key) => this.props.answersRequirements[key])

    /* turn object to array of objects and check if the questions are submit ready - personal questions */
    const personalQuestionsRequirementsArray = Object.keys(
      this.props.personalQuestionsRequirements
    ).map((key) => this.props.personalQuestionsRequirements[key])

    /* Check if the number is valid based on country */
    const isPhoneNumberValid = this.checkPhoneNumber()
    /* Check if every question is submitReady. If interviewGuide is true, remove Personal questions from check because in this case Resume upload, Personal
    information, Education and Experience are hidden */
    const submitReady =
      /* edge case when we are on quick apply job and users logs in we don't have truman questions so answersRequirementsArray is empty array without submitReady but line below returns true which is what we want.
    "every" acts like the "for all" quantifier in mathematics. In particular, for an empty array, it returns true. */
      answersRequirementsArray.every(
        (answersRequirement) => answersRequirement.submitReady === true
      ) &&
      (personalQuestionsRequirementsArray.every(
        (personalRequirement) => personalRequirement.submitReady === true
      ) ||
        this.props.interviewGuide === true) &&
      isPhoneNumberValid

    if (submitReady === true) {
      this.props.disableFullApplyFormAutosave([
        'fullApplyFormQuestionsAutosave',
        'fullApplyFormPersonalQuestionsAutosave',
      ])

      /* Since on submit we are only sending empty object in payload we must first call '/answers' endpoint
      to save everything that was filled out in the time between the last autosave and the time user clicked
      submit button. On /answers endpoint we save truman questions.
      We must also call endpoint to save Personal questions (personal, education, experience) for the same reason.  */

      if (
        typeof window !== 'undefined' &&
        this.props.answers &&
        this.props.fullApplicationForm &&
        this.props.applicant
      ) {
        this.props
          .postFullApplyFormQuestionsAutosaveAction(
            window.activeEnvironment,
            this.props.answers,
            this.props.fullApplicationForm.id,
            this.props.applicant.token,
            this.props.interviewGuide
          )
          .then(() => {
            this.props
              .postFullApplyFormPersonalQuestionsAutosaveAction({
                environment: window.activeEnvironment,
                personalAnswers: this.props.fullApplicationPersonalQuestions,
                id: this.props.fullApplicationForm.applicant_id,
                token: this.props.applicant.token,
              })
              .then((response) => {
                console.log('Submit-', response)
                this.props.openSubmitModal({
                  interviewGuide: this.props.interviewGuide,
                })
              })
              .catch((error) => {
                this.props.openTimeoutImageModal({
                  image: 'error',
                  bodyMessage: error.developer_message,
                  timeout: 3000,
                  pageReload: false,
                  redirect: false,
                  removeCloseModalOptions: false,
                })
              })
          })
          .catch((error) => {
            this.props.openTimeoutImageModal({
              image: 'error',
              bodyMessage: error.developer_message,
              timeout: 3000,
              pageReload: false,
              redirect: false,
              removeCloseModalOptions: false,
            })
          })
      } else {
        this.props.openTimeoutImageModal({
          image: 'error',
          bodyMessage:
            t('submitValidationError'),
          timeout: 6000,
          pageReload: false,
          redirect: false,
          removeCloseModalOptions: false,
        })
      }
    } else {
      let message = t('submitValidationIncomplete') 

      if (!isPhoneNumberValid) {
        message = t('submitValidationPhoneError')

        const applicationData = {
          job_id: this.props.jobDescription.id,
          job_title: this.props.jobDescription.name,
          country_code: this.props.applicant.value.country,
          phone_number: this.props.applicant.value.mobile_phone_number,
          email: this.props.applicant.value.email,
        }
        validPhoneNumberToBeInE164FormatRudderstack(applicationData);
      }

      this.props.openTimeoutImageModal({
        image: 'error',
        bodyMessage:
          message,
        timeout: 6000,
        pageReload: false,
        redirect: false,
        removeCloseModalOptions: false,
      })
    }
  }

  renderExpandQuestionButton = () => (
    <Styled.ExpandQuestionAction
      onClick={() => this.props.changeQuestionExpand(this.state.expandStatus)}
    >
      {this.state.expandStatus === 'allQuestionsExpand'
        ? this.props.t('expandAll')
        : this.props.t('collapseAll')}
    </Styled.ExpandQuestionAction>
  )

  checkPhoneNumber = () => {
    /*
      Validates if the mobile phone number entered by the candidate is valid.
      It's default country code is US (United States)
        This function will always return true if you are in an interview guide, even if the 
      applicant's phone number store on DB is NULL nor valid. The evaluation will only work 
      under apply circumstances and therefore must not disrupt the interview flow.
    */
    if (this.props.interviewGuide) {
      return true; 
    }

    const phoneNumber = this.props.applicant?.value?.mobile_phone_number
    const countryCode = this.props.applicant?.value?.country

    return phoneNumber && isValidPhoneNumber(phoneNumber, countryCode || 'US');
  }

  checkAllQuestionsExpandStatus = () => {
    /* If just one of the questions is not expanded we show Expand All.
    If all the questions are expanded we show Collapse All */

    /* We must make a double loadOptions, first through all question types and then through all
    the questions of that type. Since 'questionExpand' is object with properties, we
    have to  turn those properties to array so we can use it more easily. */

    let expandStatus = false
    const expandKeyArray = Object.keys(this.props.questionExpand)
    for (let i = 0; i < expandKeyArray.length; i += 1) {
      /* We skip empty arrays without questions */
      if (this.props.questionExpand[expandKeyArray[i]].length > 0) {
        for (
          let j = 0;
          j < this.props.questionExpand[expandKeyArray[i]].length;
          j += 1
        ) {
          if (
            this.props.questionExpand[expandKeyArray[i]][j].expand === false
          ) {
            expandStatus = true
            break
          }
        }
      }
    }

    if (expandStatus === true) {
      this.setState({
        expandStatus: 'allQuestionsExpand',
      })
    } else {
      this.setState({
        expandStatus: 'allQuestionsCollapse',
      })
    }
  }

  renderAutosaveCounter() {
    const { t } = this.props
    /* if either property is false we show the Saving message */
    if (
      this.props.fullApplyFormQuestionsAutosave === false &&
      this.props.fullApplyFormPersonalQuestionsAutosave === false
    ) {
      return <Styled.AutosaveCounter>{t('allChangesSaved')}</Styled.AutosaveCounter>
    }
    return (
      <Styled.AutosaveCounter>
        {t('saving')}
      </Styled.AutosaveCounter>
    )
  }

  render() {
    const { t } = this.props
    return (
      <Styled.FullApplyFormContainer
        id="containerElement"
        /* We hide this component until we click the button on mobile and desktop. When we click it we change
        startFullApplyFormActivate property to true and we start interval count to save answer if there were any changes */
        hidden={this.props.fullApplyFormActivate}
        customdesign={desktopDesign() ? 'desktop' : 'mobile'}
        data-gtm="full-apply"
      >
        <FullApplyFormScroll name="fullApplyFormScroll" />
        <Form
          onSubmit={() => this.handleFormSubmit()}
          name="firstInsideContainer"
        >
          {this.props.interviewGuide ? (
            <Styled.FullApplyFormHeader>
              {t('interviewQuestions')}
            </Styled.FullApplyFormHeader>
          ) : (
            <Styled.FullApplyFormHeader>{t('applyNow')}</Styled.FullApplyFormHeader>
          )}
          {/* We just send "renderExpandQuestionButton" and "renderAutosaveCounter" as a
          prop for design purpose but all the functionality is in this component */}
          <JobDescriptionFullApplyFormQuestions
            showExpandQuestionButton={this.renderExpandQuestionButton()}
            showAutosaveCounter={this.renderAutosaveCounter()}
            interviewGuide={this.props.interviewGuide}
          />
          <Styled.SubmitButtonContainer>
            <Styled.SubmitButton
              disabled={!this.state.hasAgreedToTerms && !this.props.interviewGuide}
              customcolor={
                this.props.companyProfile &&
                this.props.companyProfile.primary_color
                  ? this.props.companyProfile.primary_color
                  : '#000'
              }
            >
              {t('submit')}
            </Styled.SubmitButton>
            {this.props.interviewGuide ? null : (
              <Styled.SubmitTermsAndConditions>
                <Checkbox
                  id="agreeToTermsFullApply" 
                  checked={this.state.hasAgreedToTerms}
                  onChange={(e) => this.setState({
                    hasAgreedToTerms: e.target.checked,
                  })}
                />
                <label
                  htmlFor="agreeToTermsFullApply"
                  dangerouslySetInnerHTML={{
                    __html: t('jobDescription:termsAndConditionsText').replace(
                      '[Company Name]',
                      sanitizeString(this.props.jobDescription.brand_name)
                    ),
                  }}
                />  
              </Styled.SubmitTermsAndConditions>
            )}
          </Styled.SubmitButtonContainer>
        </Form>

        <Styled.BackToTopContainer>
          {desktopDesign() ? (
            <Link
              activeClass="active"
              to="firstInsideContainer"
              smooth
              duration={250}
              containerId="containerElement"
            >
              <Icon name="caret square up outline" />
              {t('backToTop')}
            </Link>
          ) : (
            <div onClick={this.scrollToTop}>
              <Icon name="caret square up outline" />
              {t('backToTop')}
            </div>
          )}
        </Styled.BackToTopContainer>
      </Styled.FullApplyFormContainer>
    )
  }
}

const mapStateToProps = (state) => ({
  answers: state.jobApplicationReducer.answers,
  answersRequirements:
    state.jobApplicationReducer.submitRequirements.answersRequirements,
  applicant: state.authenticationReducer.applicant,
  companyProfile: state.companyProfileReducer.companyProfile,
  fullApplicationForm: state.jobApplicationReducer.fullApplicationForm,
  fullApplicationPersonalQuestions:
    state.jobApplicationReducer.fullApplicationPersonalQuestions,
  fullApplyFormActivate: state.jobApplicationReducer.startFullApplyFormActivate,
  fullApplyFormPersonalQuestionsAutosave:
    state.jobApplicationReducer.fullApplyFormPersonalQuestionsAutosave,
  fullApplyFormQuestionsAutosave:
    state.jobApplicationReducer.fullApplyFormQuestionsAutosave,
  personalQuestionsRequirements:
    state.jobApplicationReducer.submitRequirements
      .personalQuestionsRequirements,
  questionExpand: state.jobApplicationReducer.questionExpand,
  jobDescription: state.jobDescriptionReducer.jobDescription,
})

JobDescriptionFullApplyForm.propTypes = {
  answers: PropTypes.object, // comes from parent 'jobApplicationReducer'. Contains all the answers for the questions coming from truman, type of the question and id.
  answersRequirements: PropTypes.object, // comes from parent 'jobApplicationReducer'. Contains if questions coming from truman are optional or not. It also contains submitReady property that tells us if specific question is filled out ok.
  applicant: PropTypes.object, // comes from authenticationReducer. Contains data about the current logged in user.
  changeQuestionExpand: PropTypes.func, // used to expand or collapse question accordion.
  companyProfile: PropTypes.object, // Contains data about current active company.
  disableFullApplyFormAutosave: PropTypes.func, // used to disable property that controls if autosave will trigger for truman and personal questions.
  fullApplicationForm: PropTypes.object, // original response that we get back from API when retrieve full apply questions form data (not changed or adjusted).
  fullApplicationPersonalQuestions: PropTypes.object, // contains personal questions and answers for the inputs.
  fullApplyFormActivate: PropTypes.bool, // when fullApplyFormActive is true we start the autosave interval and show full apply form.
  fullApplyFormPersonalQuestionsAutosave: PropTypes.bool, // if this property is true autosave will triggered API call to save personal questions on every interval count.
  fullApplyFormQuestionsAutosave: PropTypes.bool, // if this property is true autosave will triggered API call to save truman questions on every interval count.
  openSubmitModal: PropTypes.func, // used to open SubmitModal.
  openTimeoutImageModal: PropTypes.func, // used to open TimeoutImageModal.
  personalQuestionsRequirements: PropTypes.object, // contains submitReady property that tells if question is filled out properly. If all questions are submitReady we can submit the form.
  postFullApplyFormPersonalQuestionsAutosaveAction: PropTypes.func, // used to post personal questions during autosave to API.
  postFullApplyFormQuestionsAutosaveAction: PropTypes.func, // used to post truman questions during autosave to API.
  questionExpand: PropTypes.object, // contains all the questions and tells if question accordion is expanded or not.
  saveSubmitButtonClicked: PropTypes.func, // save if submit button for form was clicked or not. When we click the submit but questions are not filled out correctly we show errors for those questions.
  startFullApplyFormActivate: PropTypes.func, // used to change property 'startFullApplyFormActivate(redux) / fullApplyFormActive(this component)' that will signal to start autosave interval and to show full apply form.
  interviewGuide: PropTypes.bool, // tells us if we should show resume.
}

export default connect(mapStateToProps, {
  changeQuestionExpand,
  saveSubmitButtonClicked,
  disableFullApplyFormAutosave,
  postFullApplyFormQuestionsAutosaveAction,
  postFullApplyFormPersonalQuestionsAutosaveAction,
  startFullApplyFormActivate,
  openSubmitModal,
  openTimeoutImageModal,
})(withTranslation(['jobDescriptionFullApply', 'jobDescription'])(JobDescriptionFullApplyForm))
